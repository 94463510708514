const setFeatureToggles = require('./featureToggles')

const hostedZoneId = 'Z03814971PPKWZ6W1QHJI'
const environmentName = 'staging'
const domainName = 'ssr.cscshopfront-dev.com'
const Layer0DnsRecordName = 'okl.cscshopfront-dev.com'
const Layer0DnsRecordType = 'CNAME'
const Layer0DnsRecordValue = '172f78ed-e99f-4a62-9a21-9e29645f49b3.layer0-limelight.link'
const Layer0DnsSslChallengeName = '_acme-challenge.okl.cscshopfront-dev.com'
const Layer0DnsSslChallengeValue = '_acme-challenge.xdn-validation.com'
const BigCommerceDnsSslChallengeName = '_B344D9DC29AF06361EA3A58B182268AC.okl.cscshopfront-dev.com'
const BigCommerceDnsSslChallengeValue = '4EB439D36B9107C174DBDC233C978F81.AC12F913F6D8006AEA55775E35CF0D44.626b1063d18e1.comodoca.com'
const MerchantHostedZoneId = 'Z03814971PPKWZ6W1QHJI' // equal to our vendor one for now, because of the subdomain approach.
const PublicCdnDnsName = 'okl.cscshopfront-dev-cdn.com'
const PublicCdnHostedZoneId = 'Z0238238258MFRLRR3BYH'
const StaticsBucketDnsName = 'statics-sf-ssr-us-east-1-260851624216-staging.s3.amazonaws.com'
const CDN_PREFIX = `https://${PublicCdnDnsName}`

module.exports = {
  CITIZENS_SCRIPT: 'https://toolbox-uat.jifiti.com/Widgets/1.0.4/jifiti-widget.min.js',
  CITIZENS_WIDGET_KEY: '667ba9aff33b4834af23e7c0d6182d20',
  AWS_REGION: 'us-east-1',
  AWS_PROFILE: 'zgallerie',
  CHECKOUT_DOMAIN: '/checkout-frame',
  CHECKOUT_IDENTIFIER: 'okl-staging',
  DOMAIN_NAME: domainName,
  ENVIRONMENT_NAME: environmentName,
  CONFIG_FILE: 'staging.json',
  RECREATE_URL: null,
  CONTENT_PREVIEW: true,
  CONTENT_API: 'https://diuhh8z0rj.execute-api.us-east-1.amazonaws.com/Prod/v1',
  SEARCH_API: 'https://3fcovtuzgd.execute-api.us-east-1.amazonaws.com/Prod',
  SHOP_ORIGIN: 'https://okl.cscshopfront-dev.com',
  CDN_PREFIX,
  SHOP_HASH: 'ccb3mj27tc',
  GA_TRACKING_ID: 'GTM-WSF8QM4',
  FACEBOOK_PIXEL_ID: '779172446420234',
  BLOOMREACH_ACCOUNT_ID: 7294,
  IS_BLOOMREACH_TEST_ENV: true,
  DeployParameterOverrides: JSON.stringify({
    DomainName: domainName,
    EnvironmentName: environmentName,
    HostedZoneId: hostedZoneId,
    Layer0DnsRecordName,
    Layer0DnsRecordType,
    Layer0DnsRecordValue,
    Layer0DnsSslChallengeName,
    Layer0DnsSslChallengeValue,
    BigCommerceDnsSslChallengeName,
    BigCommerceDnsSslChallengeValue,
    MerchantHostedZoneId,
    PublicCdnDnsName,
    StaticsBucketDnsName,
    PublicCdnHostedZoneId,
  }),
  BACK_ORDER_CUSTOM_FIELD_ID: 'field_26',
  ORDER_EXTRA_DATA_FIELD_ID: 'field_28',
  IS_BNPL_FIELD: 'field_30',
  BOPUS_FIELD: 'field_37',
  STORE_CREDITS_APPLIED_FIELD: 'field_36',
  CUSTOM_FIELD_REWARD_EARNED: 'field_38',
  FEATURE_TOGGLES: setFeatureToggles(environmentName),
  CRITEO_ACCOUNT_ID: '12345',
  VITALS_LOGGING_API: 'https://uzj4txodu3.execute-api.us-east-1.amazonaws.com/Prod',
  PLACES_KEY: 'AIzaSyB798cGQ4KPSJEbnsFTSmQZKSShzJ0eaxI',
  HOTJAR_ID: '1548946',
  LISTRAK_ID: 'T6phidacCJdz',
  WUNDERKIND_ID: '3212',
  EXTEND_ID: '486374e0-9bc3-47b5-b21b-d6ef1a0dc794',
  EXTEND_ENVIRONMENT: 'demo',
  EXTEND_KEY: 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjhkZmIxYTQ0LTdmZDctNDhhMi1iMmJhLWM3N2Y1MTY4MjhmMCJ9.eyJlbWFpbCI6ImFudW5lc0BvbmVraW5nc2xhbmUuY29tIiwiYWNjb3VudElkIjoiYWMwZTc3NDUtYjk2YS00NzRjLThkZGYtYWZlZDM3ZjhmODQ1IiwiZ3R5IjoicGFzc3dvcmQiLCJzY29wZSI6ImRlZmF1bHQiLCJpYXQiOjE3MTA1MzY3MjYsImV4cCI6MjU1MTEyODM3NTI1LCJpc3MiOiJhcGktZGVtby5oZWxsb2V4dGVuZC5jb20iLCJzdWIiOiI2MjFjNzhlZC02YzRmLTQ5N2MtYjFkZi1lOWYzNmEwZThiZmEiLCJqdGkiOiI4JmcyVCZ7SUtMbXhrakhEXlZKaCJ9.v2ydzMDTvdx5FnaoEIS7SvXxCQz1GN12NkxZzUAyv8AP10H-PtWWdzNHaAWmriL_5zAcm9TDdUE0ag-bd5adsw',
  CITIZENS_PAY_EXPERIMENT_ID: 'n9wjfKA_REa8-8SHMb6cjQ',
  SENTRY_DSN: 'https://636b9931b0164c9f99f8d182cb8badaa@o32694.ingest.sentry.io/6611343',
  SENTRY_ENVIRONMENT: 'staging',
  SHOP_TOKEN_SCRIPT: 'https://web-js-sandbox.bigshoptoken.com/main.js?retailer-name=okl&platform=big-commerce',
  GraphQLStoreFrontJWT: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjaWQiOjEsImNvcnMiOlsiaHR0cHM6Ly9va2wuY3Njc2hvcGZyb250LWRldi5jb20iLCJodHRwczovL3d3dy5va2wuY3Njc2hvcGZyb250LWRldi5jb20iXSwiZWF0IjoyMTQ3NDAzNTk5LCJpYXQiOjE2NTA2NDA1MzYsImlzcyI6IkJDIiwic2lkIjoxMDAxNjYxNzg2LCJzdWIiOiJjcDFyNXB4NjRjajhidmRkdjFvbjliNjJlOTcwNzM0Iiwic3ViX3R5cGUiOjIsInRva2VuX3R5cGUiOjF9.vcxXu3WLGR2gNHUMKk9GsQ4JNqk3wwtu3rsebEifthcWsyvfqVXcy-lD9UXS6_ZM8445xMh-_yMabCrsSNdbzQ', // Expires at 2147403599  allows ['https://okl.cscshopfront-dev.com', 'https://www.okl.cscshopfront-dev.com']
  OAUTH_API: '/oauth',
  ReadonlyBigCAccessToken: '6jnb3m5j84ju5bweck4jpiqexl27dbp',
  CSC_SHOPFRONT_API: 'api.cscshopfront-dev.com',
  CSC_PAYMENTS_HOST: 'api.cscpaymentsserviesdev.com',
  CSC_PAYMENTS_PK: 'pk_test_51MkGXCC8n6OBWmc3HhNY5WpxXiuzOdns3SqVOPFp9cAYsaJ3kTW1WJIThk8rSdayaOrUKxmV2WAFJZc0uwZW9Wya00hxVikfzr',
  CSC_PAYMENTS_GATEWAY: 'okl-staging-stripe',
  CSC_APPLE_PAY_GATEWAY: 'okl-staging',
  CSC_PAYMENTS_SHOP: 'okl-staging',
  CSC_IAM_AUD: 'q8vrvfxlc60hinovcz4olteq32g90dp',
  CSC_IAM_REGISTERED_IDP: 'okl-staging-registered',
  CSC_IAM_GUEST_IDP: 'okl-staging-guests',
  CSC_IAM_REGISTERED_ROLE: 'SignedInStagingOklShopFrontCustomer',
  CSC_IAM_GUEST_ROLE: 'GuestStagingOklShopFrontCustomer',
  CSC_IAM_ACCOUNT: '000000000007',
  CSC_SHOPFRONT_MERCHANDISING_IDENTITY: 'okl-website-our-picks',
  CSC_SHOPFRONT_PRODUCT_MERCHADISING_ROLE: 'OklSiteProductMerchandiser',
  FINGERPRINT_ORG_ID: 'onekingslane_qa_tst_acct',
  FINGERPRINT_MERCHANT_ID: 'onekingslane_qa_tst',
  GUEST_TOKEN_NAME: 'guestToken',
  KUSTOMER_API_KEY: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyZmQ2NDI5MzcyOGZkNGJhM2QxZTcwOSIsInVzZXIiOiI2MmZkNjQyOWYyOTA5MDE5MDliOGIxZDQiLCJvcmciOiI2Mjk2NWQxMzAxMWM3ODM5ZDcxNTZiZDciLCJvcmdOYW1lIjoiY3NjZ2VuZXJhdGlvbiIsInVzZXJUeXBlIjoibWFjaGluZSIsInBvZCI6InByb2QxIiwicm9sZXMiOlsib3JnLnRyYWNraW5nIl0sImF1ZCI6InVybjpjb25zdW1lciIsImlzcyI6InVybjphcGkiLCJzdWIiOiI2MmZkNjQyOWYyOTA5MDE5MDliOGIxZDQifQ.ADtFxPD5ZVM8Yxw9TaBtyhj0Fx3XYYHo8RjvvwN32Gg',
  KUSTOMER_BRAND_ID: '62e828adaa1195daf50c2d64',
  CJ_DOMAIN: '.cscshopfront-dev.com',
  LISTRAK_SUBSCRIPTION_LIST: 'BCGuestCheckout',
  OPTIMIZE_CONTAINER_ID: 'OPT-5VBDZMP',
  APPLE_PAY_MERCHANT_ID: 'merchant.com.cscshopfront-dev.com',
  APPLE_PAY_SCRIPT: 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js',
  APPLE_PAY_EXPERIMENT_ID: 's-KAwb9sRDC7j83oTNZyOg',
  PAYPAL_EXPERIMENT_ID: 'g8DAzypGRS2gBkbYOg5VTg',
  VWO_ACCOUNT_ID: '681566',
  CONNEXITY_ACCOUNT_ID: '253757',
  PROMO_EXPERIMENT_ID: 'Nii8UzTsQE67MJiC07JvbA',
  DISABLE_CC: false,
  ARRIVAL_DATE_EXPERIMENT_ID: 'k0GDwB6oTIyyXiCS1nOLDw',
  SHIPPEDSHIELD_EXPERIMENT_ID: 'WA-KREwMQPuqzFnxR_zFDQ',
  PAYPAL_ID: 'ATOIMqIDDdp_yi-hYpuc9H6JGESGKAgWFw8PkGZe7SwcekEJ-8CDgZEexEQZlXgqrXWX3hWVyeYyUmUs',
  TURNTO_SITE_KEY: 'pxXM8lVGFRLMRptsite',
  SHIPPING_RATE_API: 'https://v2ywixww39.execute-api.us-east-1.amazonaws.com/Prod',
  USE_LOCAL_CSC_CREDENTIALS: false,
  ORDER_METADATA_FIELD_ID: 'field_42',
  FREE_SHIPPING_ELIGIBLE: 'field_44',
  SMS_OPT_IN_FIELD_ID: 'field_46',
  PUBLIC_DNS_CDN_NAME: PublicCdnDnsName,
  CDN_API: CDN_PREFIX,
  SEZZLE_MERCHANT_ID: 'a935c024-f592-490a-8dbf-f897449cb48e',
  COGNITO_REGION: process.env.COGNITO_REGION || 'us-east-1',
  COGNITO_IDENTITY_POOL_ID: process.env.COGNITO_IDENTITY_POOL_ID || 'us-east-1:bd154cbd-a188-4faa-88be-a0054843b1ed',
  FIREHOSE_REGION: process.env.FIREHOSE_REGION || 'us-east-1',
  FIREHOSE_DELIVERY_STREAM_NAME: process.env.FIREHOSE_DELIVERY_STREAM_NAME || 'test-firehose-to-http-endpoint',
}
